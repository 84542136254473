<template>
  <div class="sign-page mb-14">
    <div class="max-width">
      <v-row align="center">
        <v-col cols="12" class="py-1" md="5" >
          <img class="mt-12" width="100%" :src="require('@/assets/images/auth/bg.png')" />
        </v-col>
        <!-- page content -->
        <v-col cols="12" class="py-1" md="6" offset-md="1">
          <span class="d-block primary--text font-500 font-40 mt-12">{{
            $t("signUp")
          }}</span>
          <div
            class="sign-page__content text-center d-flex align-center justify-center h-100 mt-6"
          >
            <!-- form -->
            <v-form
              class="w-100"
              v-model="valid"
              @submit.prevent="submitHandler"
              ref="signupForm"
            >
              <v-row align="center">
                <!-- image user -->
                <v-col cols="12" class="py-1">
                  <div class="upload-img text-center mt-5">
                    <img
                    width="120px"
                    height="120px"
                      :key="keyUpdated"
                      class="cursor-pointer object-cover d-block"
                      :src="
                        file.url
                          ? file.url
                          : require('@/assets/images/global/img-empty.png')
                      "
                    />
                    <v-file-input
                      clear-icon="upload-img__btn"
                      hide-input
                      @change="uploadImage"
                    ></v-file-input>
                  </div>
                </v-col>

                <!-- first name -->
                <v-col cols="6">
                  <base-input
                    :placeholder="$t('firstName')"
                    v-model="user.first_name"
                  ></base-input>
                </v-col>

                <!-- last name -->
                <v-col cols="6">
                  <base-input
                    :placeholder="$t('lastName')"
                    v-model="user.last_name"
                  ></base-input>
                </v-col>

                <!-- email -->
                <v-col cols="12" class="py-1">
                  <base-input
                    class="mt-3"
                    :placeholder="$t('email')"
                    v-model="user.email"
                    :rules="$helper.emailRules"
                  ></base-input>
                </v-col>

                <!-- password -->
                <v-col cols="12" class="py-1">
                  <base-input
                    class="mt-5"
                    :placeholder="$t('password')"
                    v-model="user.password"
                    type="password"
                  ></base-input>
                </v-col>

                <!-- terms -->
                <v-col cols="12" class="py-1">
                  <v-checkbox
                    color="primary"
                    v-model="terms"
                    :hide-details="true"
                    :label="$t('IAgree')"
                    :error="isEmpty"
                  >
                    <template v-slot:label>
                      <span class="primary--text font-20 font-300">
                        {{ $t("IAgree") }}
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>

                <!-- actions -->
                <v-col cols="12" class="py-1">
                  <v-btn
                    color="primary"
                    class="mt-6"
                    block
                    height="45"
                    elevation="0"
                    type="submit"
                    :disabled="!valid"
                    ><span class="font-20 font-300 white--text">{{
                      $t("signUp")
                    }}</span></v-btn
                  >
                </v-col>

                <v-col cols="12" class="py-1">
                  <span class="d-block black--text font-20 mt-5 mb-2"
                    >{{ $t("alreadyhaveAnAccount") }}?
                    <router-link to="/sign-in">
                      <span class="primary--text">{{
                        $t("signIn")
                      }}</span></router-link
                    ></span
                  >
                </v-col>
              </v-row>
            </v-form>

            <!-- <span class="d-block black--text font-20 mt-5 mb-2">Or </span> -->
            <!-- facebook -->
            <!-- <v-btn class="c-btn-gradient" rounded 
                min-width="160"
                height="35">
              <div class="px-4 py-2 d-flex align-center">
                <img
                  class="mr-6"
                  :src="require('@/assets/images/global/facebook-char.png')"
                  alt=""
                />
                <span class="font-15 font-300 white--text"
                  >Sign in with Facebook</span
                >
              </div>
            </v-btn> -->
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    valid: false,
    terms: false,
    user: {},
    file: {},
    keyUpdated: false,
    isEmpty: false,
  }),
  computed: {
    ...mapGetters(["fcmToken"]),
  },
  methods: {
    uploadImage(file) {
      this.file.url = URL.createObjectURL(file);
      this.file.file = file;
      this.keyUpdated = !this.keyUpdated;
      this.isEmpty = false;
    },
    async submitHandler() {
      let valid = this.$refs.signupForm.validate();
      if (!valid || !this.terms) {
        this.isEmpty = true;
        return;
      }
      this.$axios.defaults.headers.common = "";
      let formData = new FormData();
      formData.append("first_name", this.user.first_name);
      formData.append("last_name", this.user.last_name);
      formData.append("email", this.user.email);
      formData.append("password", this.user.password);
      if (this.file.file) {
        formData.append("image", this.file.file);
      }
      const { data } = await this.$axios.post("/auth/register", formData);
      if (data.success) {
        this.$store.dispatch("setUser", data.data);
        this.$store.dispatch("showSnack", {
          text: "User created  successfully",
          color: "success",
        });
        localStorage.setItem("verifyEmail", this.user.email);
        this.$router.replace("/verify-account");
      } else {
        this.$store.dispatch("showSnack", {
          text: Object.values(data)[0].toString(),
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.sign-page {
  margin-top: 120px;
  margin-bottom: 120px;
  &__content {
    padding: 0px 20px;
    max-width: 550px;
  }

  .upload-img {
    position: relative;
    border: 1px solid transparent;
    transition: 0.3s;
    border-radius: 15px;
    overflow: hidden;
    background: #ededed;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: auto;

    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%)
    }
    .v-input,
    &__btn,
    button {
      margin-top: 0px;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
      display: block;
      padding: 0px;
    }

    &--active {
      border: 1px solid red;
    }
  }
}
</style>
